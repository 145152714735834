

import UserPersonalInfo from "../components/manager/employeeprofile/UserPersonalInfo";


function PersonalInfo() {
  return (
    <div>
      <UserPersonalInfo/>
    </div>
  );
}

export default PersonalInfo;
