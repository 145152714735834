import { useEffect, useState } from "react";
import { put } from '../../api/apiCalls'
import { ReactSession } from 'react-client-session';
import './ComplianceModal.css'
import { putCompliance } from "../../api/env";

function ComplianceModal({compliance, onCloseModal, onCloseModalByClick, onCloseAfterSaving}){
    let [disabled, setDisabled] = useState(true)
    const [agentDiscussionSummary, setAgentDiscussionSummary] = useState(compliance.agentDiscussionSummary || "");
    const [closureReason, setClosureReason] = useState(compliance.closureReason || "");
    const [agentDiscussionRemaining, setAgentDiscussionRemaining] = useState(250 - (compliance.agentDiscussionSummary?.length || 0));
    const [closureReasonRemaining, setClosureReasonRemaining] = useState(50 - (compliance.closureReason?.length || 0));
    const user = ReactSession.get('User')

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onCloseModal(); 
            }
        };

        const handleClickOutside = (event) => {
            if (event.target.classList.contains('compliance-modal')) {
                onCloseModalByClick();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('click', handleClickOutside);
        };
    }, [onCloseModal, onCloseModalByClick]);

    function handleCloseModal(){
        onCloseModalByClick()
        
    }

    function handleClosureReason(e){
        const value = e.target.value;
        setClosureReason(value);
        setClosureReasonRemaining(50 - value.length);
        setDisabled(agentDiscussionSummary.trim() === "" || value.trim() === "");
    }

    function handleAgentDiscussion(e){
        const value = e.target.value;
        setAgentDiscussionSummary(value);
        setAgentDiscussionRemaining(250 - value.length);
        setDisabled(value.trim() === "" || closureReason.trim() === "");
    }

    function handleReviewedWithAgentStatus(e){
        if(e.target.value !== compliance.reviewedWithAgent)
            setDisabled(false)
        compliance.reviewedWithAgent = e.target.value
    }

    function handleIsOpenStatus(e){
        if(e.target.value !== compliance.isOpen)
            setDisabled(false)
        compliance.isOpen = e.target.value
    }

    
    async function handleComplianceUpdate(event){
        event.preventDefault();
        let keys = {
            ID: compliance.id,
            ReviewedWithAgent: compliance.reviewedWithAgent,
            AgentDiscussionSummary: agentDiscussionSummary,
            ClosureReason: closureReason,
            IsOpen: compliance.isOpen,
            Updatedby: user.firstName + ' ' + user.lastname
        }
        let response = await put(putCompliance, keys)
        onCloseAfterSaving(response)
    }

    return(
        <div className="compliance-modal">
            <div className="compliance-modal-box">
                <h4>Compliance Item</h4>
                <form action="submit" className="compliance-modal-form" onSubmit={handleComplianceUpdate}>
                    <div className="compliance-modal-item-header">
                        <div>
                            <label htmlFor="compliance-id">Compliance #</label>
                            <input id="compliance-id" type="text" readOnly defaultValue={compliance.id} />
                        </div>
                        <div>
                            <label htmlFor="compliance-user">User</label>
                            <input id="compliance-user" type="text" readOnly defaultValue={compliance.user} />
                        </div>
                        <div>
                            <label htmlFor="compliance-manager">Manager</label>
                            <input id="compliance-manager" type="text" readOnly defaultValue={compliance.manager} />
                        </div>
                        <div>
                            <label htmlFor="compliance-project">Project</label>
                            <input id="compliance-project" type="text" readOnly defaultValue={compliance.project} />
                        </div>
                    </div>

                    <div className="compliance-modal-item-body">
                        <div>
                            <label htmlFor="compliance-type">Compliance Type</label>
                            <input id="compliance-type" type="text" readOnly defaultValue={compliance.complianceType} style={{width:'300px'}}/>
                        </div>
                        <div>
                            <label htmlFor="compliance-detail">Compliance Detail</label>
                            <textarea id="compliance-detail" value={compliance.detail} rows={3} cols={60} readOnly/>
                        </div>
                    </div>

                    <div className="compliance-modal-item-body">
                        <div className="compliance-item-body-info">
                            <div>
                                <label htmlFor="compliance-reviewed">Reviewed with agent</label>
                                <select id="compliance-reviewed" onChange={handleReviewedWithAgentStatus} defaultValue={compliance.reviewedWithAgent}>
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="compliance-isOpen">Open</label>
                                <select id="compliance-isOpen" onChange={handleIsOpenStatus} defaultValue={compliance.isOpen}>
                                    <option value={false}>Close</option>
                                    <option value={true}>Open</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="compliance-createDate">Created at</label>
                                <input id="compliance-createDate" type="text" defaultValue={compliance.createDate} readOnly/>
                                
                            </div>
                        </div>
                    </div>

                    <div className="compliance-modal-item-discussion-closures">
                        <div>
                            <label htmlFor="compliance-discussion">Agent discussion summary (max. 250)</label>
                            <textarea id="compliance-discussion" defaultValue={compliance.agentDiscussionSummary} rows={3} onChange={handleAgentDiscussion} maxLength={250}/>
                            <small>{agentDiscussionRemaining} characters remaining</small>
                        </div>
                        <div>
                            <label htmlFor="compliance-closure">Closure reason (max. 50)</label>
                            <input id="compliance-closure" type="text" defaultValue={compliance.closureReason} onChange={handleClosureReason} maxLength={50}/>
                            <small>{closureReasonRemaining} characters remaining</small>
                        </div>
                    </div>
                    <div className="compliance-form-btns">
                        <button type="submit" className="compliance-form-btn-submit" disabled={disabled}>Save</button>
                        <button type="reset" onClick={handleCloseModal} className="compliance-form-btn-reset">Close</button>
                    </div>
                </form> 
            </div>
        </div>
    )

}


export default ComplianceModal;