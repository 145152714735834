
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import EmployeeProfileMain from "../components/layout/main/EmployeeProfileMain";
import Joyride from "react-joyride"
import { useNavigate } from "react-router-dom";
import React, { useState,useMemo  } from 'react';
import { ReactSession } from "react-client-session"
import { FaUser } from "react-icons/fa";

function EmployeeProfile() {
  const history = useNavigate();
  const [isFirstLogin, setIsFirstLogin] = useState('true')
  const [{ run, steps }, setState] = useState({
    run:true,
    steps: [
      {
        content: "Here you can find your personal information on the system.",
        title: <h2><FaUser/> Personal Info</h2>,
        locale: {skip: <strong>SKIP</strong>},
        placement: "center",
        target: 'body',
        disableOverlay: false,
        spotlightClicks: false

      },
      {
        content: 'The detail section houses all your basic information. Please edit/add your details to complete your profile before continuing.',
        placement: "bottom",
        target: "#step-4",
        title: <h2>Details</h2>,
        spotlightClicks: true,
      }
    ]
  })
  


  const handleTourEvent = (event) => {
    if(event.index == 1 && event.status == 'finished' || event.action == 'skip'){
      // history("/profile/payment")
      history("/profile/work")
    }
  }
  useMemo(() => {
    const user = ReactSession.get("User");
    setIsFirstLogin(user.isFirstLogin)
  
  },[]);
  return (
    <div>
      {isFirstLogin && (
        <Joyride
          continuous
          showSkipButton
          run={run}
          steps={steps}
          showProgress
          disableScrolling
          callback={handleTourEvent}
          styles={
            {
              options:{
                zIndex: 9999
              },
              spotlight: {
                minHeight:'100%',
              }
            } 
        }
        />
      )}
      
      <EmployeeProfileMain />
    </div>
  );
}

export default EmployeeProfile;
