import "../../ui/TimezoneSelector.css";
import "./PersonalInfo.css"
import { useState , useMemo } from "react";
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import { fetchImageFromAPI, post } from "../../api/apiCalls";
import { useParams } from "react-router-dom";
import moment from "moment";
import UserTextInput from "../../ui/UserTextInput";
import UserShorProfile from "../../elements/summaryElements/UserShorProfile";
import { baseURL, getTrackingDataProfilePicture, postUserById } from "../../api/env";
import DefaultProfileImage from '../../../images/default.jpg'


function UserPersonalInfo(props) {
  const params = useParams();
  let [euser,setUser] = useState([]);
  const [loading, setLoading] = useState(true)

  const getUser = async() => {
    let keys = {
      id : params.employeeId
    };
    let u = await post(postUserById,keys);
    u.image = u.image === null ? DefaultProfileImage : await fetchImageFromAPI(`${baseURL}${getTrackingDataProfilePicture}?ImagePath=${u.image}`)
    setUser(u);
    setLoading(false)
  }
  
  
  useMemo(() => {
    getUser();
    
    // componentWillMount events
  },[]);
  return (
    <div className="summary">
      {!loading && (
        <div className="summaryMain">
          <div className="my-dashboard-header">
            <UserShorProfile
              employeeName={euser.firstName + ' ' + euser.lastname}
              employeePronoun={euser.pronoun}
              employeeDesignation={euser.jobDescription}
              employeeID={euser.id}
              employeeUsername={euser.username}
              employeeLocation={euser.city}
              employeePicture={euser.image}
              employeeDisabled={euser.disabled}
              employeeContractEndDate={euser.contract_endDate}
            />
            <ManagerSubHeaderLinks id={params.employeeId}/> 
          </div>
          <div className="personal-info-header">Details</div>
          
          <form className="personal-info-summary">
            <UserTextInput
              text="First Name"
              type="text"
              required="required"
              value={euser.firstName}
          
              define={"firstName"}
            />
            <UserTextInput
              text="Last Name"
              type="text"
              required="required"

              value={euser.lastname}
            />
            <UserTextInput
              text="Email"
              type="email"
              required="required"

              value={euser.username}
            />
            <UserTextInput
              text="Job Title"
              type="text"
              required="required"
              value={euser.job_Description}
            />

            <UserTextInput
              text="Phone"
              type="text"

              value={euser.phone}
            />
            <UserTextInput
              text="Preferred Pronoun"
              type="text"
              required="required"

              value={euser.pronoun}
            />
            <UserTextInput
              text="Birthday - mm/dd/yyyy"
              type="text"
              required="required"

              value={moment(euser.birthday).format('LL')}
            />
            <UserTextInput
              text="City / Country"
              type="text"
              required="required"

              value={euser.city}
            />
            <UserTextInput text="Emergency Contact" 
              type="text" 
              value={euser.emergency_Contact}
              required="required"
              />
            
            <UserTextInput
              text="Personal Email"
              type="text"
              required="required"

              value={euser.personal_Email}
            />

            <UserTextInput
              text="Contract Expire Date"
              type="text"
              required="required"

              value={euser.contract_endDate}
            />

            <UserTextInput
              text="Contract Status"
              type="text"
              required="required"

              value={euser.contract_Status}
            />

            <UserTextInput
              text="Timezone"
              type="text"
              required="required"

              value={euser.timezone}
            />
          
          </form>
          
        </div>
      )}
    </div>
  );
}

export default UserPersonalInfo;
