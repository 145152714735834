import { GrClose } from 'react-icons/gr';
import { useState, useMemo, useEffect } from 'react';
import { get, post } from '../../api/apiCalls'
import Select from 'react-select'
import './ProjectsModal.css';
import './AdditionalPaymentModal.css';
import { getUserAll, postUserById } from '../../api/env';

function AdditionalPaymentModal(props) {
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    let user;
    const [userId, setUserId] = useState('')
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [reason, setReason] = useState('')
    const [amount,setAmount] = useState('')
     
    const reasons = [
        "Missed payment from the prior month",
        "Customer bonus",
        "Commission"
    ]

    const getUsers = async() => {
        let list = []
        const response = await get(getUserAll, {disabled:false})
        response.forEach(e => {
            let model = {
                value:'',
                label:''
            }
            model.value = e.id
            model.label = `${e.firstName} ${e.lastname}`
            list.push(model)
        });
        setUsers(list)
    }

    const getUser = async(id) => {
        let keys = {
            id : id
          };
          let response = await post(postUserById, keys).then()
          let userFullName = response.firstName + ' ' + response.lastname
          setSelectedUser(response)
          setUserId(response.id)
          setUserEmail(response.username)
          setUserName(userFullName)
    }

    const handleSelectChange = (user) => {
        getUser(user.value)
    };

    const handleSelectedReason = (event) => {
        setReason(event.target.value)
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
            props.onCancel(); 
            }
          };
      
          window.addEventListener('keydown', handleKeyDown);
      
          return () => {
              window.removeEventListener('keydown', handleKeyDown);
          };
    }, [props.onCancel])

    useMemo(() => {
        getUsers();
      },[]);

    return (
    <div className='form-modal project-modal additional-payment-modal' id='project-form-modal'>
        <div className='close-modal' onClick={props.onCancel}>
        <GrClose />
        </div>
        <div id='add-project-form'>
        <div className='project-details-card'>
            <div className='project-headers'>Add Payment</div>
            <div className='project-card'>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="color"
                    value={user}
                    defaultValue={user}
                    onChange={handleSelectChange}
                    options={users}
                />
            </div>
        </div>
        {selectedUser && (
            <div className="user-rate add-payment-form">
                <div>
                    <label htmlFor='amount'>Amount: </label>
                    <input
                    type="number"
                    value={amount}
                    id='amount'
                    min={0}
                    onChange={e => setAmount(e.target.value)}
                    />
                </div>
                <div className='select-div'>
                    <label htmlFor='reason'>Reason: </label>
                    <select id='reason' onChange={handleSelectedReason}>
                        <option value="default">Select an reason</option>
                        {reasons.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                        ))}
                    </select>
                </div>
                <div className='btn-and-alert'>
                    <button
                    id='add-project-btn'
                    disabled={!selectedUser || !amount || !reason || reason == 'default'}
                    onClick={() => props.addPayment({userID:userId, userName:userName, userEmail:userEmail, reason:reason, amount:amount})} className='project-info-button'>
                    Save
                    </button>
                </div>
            </div>
        )}
        </div>
    </div>
    );
}

export default AdditionalPaymentModal;
