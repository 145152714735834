import { FiChevronDown } from "react-icons/fi";
import { ImWindows8 } from "react-icons/im";
import { SiApple } from "react-icons/si";
import { RiInformationFill } from "react-icons/ri"
import "./DownloadButton.css";
import { useEffect, useState } from "react";
import { get } from "../api/apiCalls";
import { getDesktopAppVersion } from "../api/env";
//import macos from "sftp://ubuntu@wisdom.sirius-support.com/srv/data/MacOS/Wisdom%201.3.zip"

function DownloadButton(props) {
  const [appVersion, setAppVersion] = useState('')


  const getAppVersion = async () => {
    let version = await get(getDesktopAppVersion)
    setAppVersion(version)
  }
  useEffect( () => {
   getAppVersion()
  }, [appVersion])

  return (
    <div className="download-toggle-option" id='step-2'>
      <div className="downloadbutton">
        DOWNLOAD <FiChevronDown />
      </div>
      <div className="download-options">
        <div className="download-option">
          <a href={`https://github.com/SiriusSupportDevTeam/wisdom-tracker/releases/download/v${appVersion}/Wisdom-Tracker-${appVersion}.exe`}>
            <ImWindows8 /> Windows
          </a>
        </div>
        <div className="download-option">
          <a href={`https://github.com/SiriusSupportDevTeam/wisdom-tracker/releases/download/v${appVersion}/Wisdom-Tracker-${appVersion}.dmg`}>
            <SiApple /> MacOS
          </a>
        </div>
        <div className="download-option">
          <a href={`https://github.com/SiriusSupportDevTeam/wisdom-tracker/releases/download/v${appVersion}/Wisdom-Tracker-Guide.pdf`}>
            <RiInformationFill /> User Guide
          </a>
        </div>
      </div>
    </div>
  );
}
export default DownloadButton;
