import "./AdminSettings.css";
import "./AdminWebTrackerPage.css";
import { get, post, put } from "../api/apiCalls";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { useMemo, useRef, useState } from "react";
import AdminHeaderLinks from "../layout/header/AdminHeaderLinks"
import Select from 'react-select'
import { Toast } from "primereact/toast";
import { getUserAll, postUserById, putUsersWebTracker } from "../api/env";

function AdminWebTrackerPage () {
    const toast = useRef(null)
    let [user, setUser] = useState();
    let [usersInSelect, setUsersInSelect] = useState([]);
    let [selectedUser,setSelectedUser] = useState();
    let [webTrackerStatus, setWebTrackerStatus] = useState(false);

    const getUsers = async () => {
        let list = []
        let response = await get(`${getUserAll}?disabled=false`);
        response.forEach(e => {
            let model = {
                value: '',
                label: ''
            }
            model.value = e.id
            model.label = `${e.firstName} ${e.lastname}`
            list.push(model)
        });
        setUsersInSelect(list)
    }
    
    const handleSelectChange = (user) => {
        getUserById(user.value)
    };
    
    const getUserById = async (id) => {
        let keys = {
            id
        }
        let response = await post(postUserById, keys).then()
        let u = {
            id: response.id,
            firstName: response.firstName,
            lastname: response.lastname,
            username: response.username,
            webTracker: response.webtracker_enabled,
        }
        setSelectedUser(u);
        setWebTrackerStatus(u.webTracker);
    }


    const handleWebTrackerSelectChange = (event) => {
        setWebTrackerStatus(event.target.value)
    }

    const showInfo = (text) => {
        toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
    }


    const UpdateUserWebTrackerStatus = async() => {
        let keys = {
            status: webTrackerStatus,
            userID: selectedUser.id
        }
        let response = await put(putUsersWebTracker, keys)
        if(response){
            showInfo(response)

        }
    }

    useMemo(() => {
        getUsers()
      },[]);

    return (
   
    <div className="settingsContainer">
        <AdminHeaderLinks/>
        <Toast ref={toast} position="top-center"/>
        {  
            <Box sx={{ flexGrow: 1 }} className="settingsBody">
                <Grid container spacing={2}>
                    <Grid xs={10}>
                        <h3 className="settingsLabel">Web Tracker</h3>

                    </Grid>
                    <Grid xs={10} className="update-fields">
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Users..."
                            name="color"
                            value={user}
                            defaultValue={user}
                            onChange={handleSelectChange}
                            options={usersInSelect}
                        />
                        {selectedUser && (
                            <div className="options-box">
                                <select 
                                className="web-tracker-status-select"
                                onChange={handleWebTrackerSelectChange}
                                value={webTrackerStatus}
                                >
                                    <option key='2' value="true">On</option>
                                    <option key='3' value="false">Off</option>
                                </select>
                                <button 
                                    onClick={UpdateUserWebTrackerStatus} 
                                    className="save-webtracker-btn"
                                    disabled={webTrackerStatus == 'default'}
                                    >Save
                                    </button>   
                            </div>
                        )}
                    </Grid>
                    
                </Grid>
            </Box>
        }
    </div>);
}

export default AdminWebTrackerPage;