import { Link } from "react-router-dom";
import EmployeeCard from "../../elements/summaryElements/EmployeeCard";
import "./EmployeeSidebar.css";
import { get, fetchImageFromAPI } from "../../api/apiCalls";
import React, { useState,useMemo } from "react";
import { ImSearch } from "react-icons/im";
import { IconContext } from "react-icons";
import "../../ui/SearchButton.css";
import Switch from "react-switch";
import { baseURL, getTrackingDataProfilePicture, getUsersAsync, getUsersSearchUsers } from "../../api/env";
import DefaultProfileImage from '../../../images/default.jpg'
import { BounceLoader } from 'react-spinners'

function EmployeeSidebar () {
  let [users,setUsers] = useState([]);
  let [items,setItems] = useState([]); 
  let [disabled,setDisabled] = useState(false); 
  let [loading, setLoading] = useState(true);
  let [hasNext, setHasNext] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);
  let [totalUsers, setTotalUsers] = useState(0);
  let [loadedUsers, setLoadedUsers] = useState(0);
  let [disableSearch, setDisableSearch] = useState(true);
  let [disableSearchInfos, setDisableSearchInfos] = useState(false)
  let [employee, setEmployee] = useState("");
  
  const toggleOnlyActive = async() => {
    disabled = !disabled;
    setDisabled(disabled);
    setHasNext(false)
    setTotalUsers(0)
    setLoadedUsers(0)
    setUsers([]);
    setItems([]);
    users = null;
    await getSummaryDetails(null);
  }
  
  const getSummaryDetails = async (searchParam) => {
    setDisableSearchInfos(false)
    users = await get(`${getUsersAsync}?pageNumber=1&pageSize=10&disabled=${disabled}`).then();
    setHasNext(users.hasNextPage)
    setTotalUsers(users.totalResults)
    setLoadedUsers(users.totalLoaded)
    let u = users.users;
    await fetchImages(users.users)
    setUsers(u);
    let datas =[];
    for (const user of users.users) {
      if(searchParam!=null)
        if(!user.firstName.toLowerCase().includes(searchParam.toLowerCase()))
          continue;
      if(user.image == null)
        user.image = "default";
      let link = "/manager/summary/"+user.id;
      datas.push( <Link to={link}>
        <EmployeeCard
        employeeName={user.firstName+' '+user.lastname}
        employeeDesignation={user.job_Description}
        employeeID={user.id}
        employeePicture={user.image}
        location={user.city}
      />
      </Link>);
    }
    setItems(datas);
    setLoading(false)
      
  };
 
  const loadNextPage = async() => {
    setLoading(true)
    let nextPage = pageNumber + 1
    setPageNumber(nextPage)
    let nextUsers = await get(`${getUsersAsync}?pageNumber=${nextPage}&pageSize=10&disabled=${disabled}`).then();
    setHasNext(nextUsers.hasNextPage)
    setLoadedUsers(nextUsers.totalLoaded)
    let u = nextUsers.users;
    await fetchImages(nextUsers.users)
    setUsers(prevUsers => [...prevUsers, ...u]);
    let datas =[];
    for (const user of nextUsers.users) {
      if(user.image == null)
        user.image = "default";
      let link = "/manager/summary/"+user.id;
      datas.push( <Link to={link}>
        <EmployeeCard
        employeeName={user.firstName+' '+user.lastname}
        employeeDesignation={user.job_Description}
        employeeID={user.id}
        employeePicture={user.image}
        location={user.city}
      />
      </Link>);
    }
  
    setItems(prevItems => [...prevItems, ...datas]);
    setLoading(false)
  }

  const search = async () => {
    setDisableSearchInfos(true)
    setLoading(true)
    let users = await get(`${getUsersSearchUsers}?searchString=${employee}`)
    await fetchImages(users)
    let datas =[];
    for (const user of users) {
      let link = "/manager/summary/"+user.id;
      datas.push( <Link to={link}>
        <EmployeeCard
        employeeName={user.firstName+' '+user.lastname}
        employeeDesignation={user.job_Description}
        employeeID={user.id}
        employeePicture={user.image}
        location={user.city}
        />
      </Link>);
    }
    setItems(datas);
    setLoading(false)
  }

  function handleKeyPress(event){
    if (event.key === 'Enter') {
      search();
    }
  }

  const setEmployeeName = (value) => {
    setEmployee(value)
    if(value.length <= 0){
      getSummaryDetails();
      setDisableSearch(true)
    }else if(value.length >= 3){
      setDisableSearch(false)
    }
  } 

  const fetchImages = async(items) => {
    await Promise.all(items.map(async (data) => {
      if(data.image === null){
        data.image = DefaultProfileImage
      }else{
        let image = await fetchImageFromAPI(baseURL + getTrackingDataProfilePicture +"?ImagePath="+data.image);
        data.image = image;
      }
    }));
  }
  useMemo(() => {
    
    getSummaryDetails(null);
    // componentWillMount events
  },[]);

    return (
      <>
        <div className="employee-sidebar">
          {/* <SearchButton searchButtonText="Search Employee" onchange={searchParam}/> */}
          <div className="sidebar-search-button">
            <div className="search-button-text search-input">
                <input className="search-input-text" placeholder="Search Employee" onKeyDown={handleKeyPress} onChange={e => setEmployeeName(e.target.value)} />
                <IconContext.Provider
                value={{
                  color: "black",
                  size: "30px",
                }}
                >
                <button 
                className="sideBarSearchBtn"  
                onClick={search}
                disabled={disableSearch}
                >
                  <ImSearch className="sideBarSearchIcon"/>
                </button>
              </IconContext.Provider>
            </div>
            {!disableSearchInfos && (
              <>
                <div className="onlyActive">
                  <label htmlFor="onlyActive" >Only Active</label>
                  {/* <input checked={disabled} type="checkbox" name="onlyActive" onChange={() => toggleOnlyActive()}/> */}
                  <Switch onChange={() => toggleOnlyActive()} checked={!disabled} />
                </div>
                <p className="users-counter">USERS: {loadedUsers}/{totalUsers}</p>
              </>
            )}
            {loading && (
              <div className="loading-div">
                <BounceLoader color="#004225"/>
              </div>
            )}
          </div>
          <div className="employee-cards-list">
            {items}
          </div>
        </div>
        {!disableSearchInfos && hasNext &&(
          <div className="sidebar-search-users-footer">
            <button onClick={loadNextPage}>Load More Agents</button>
          </div>
        )}
      </>
    );
  
}

export default EmployeeSidebar;
