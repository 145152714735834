import "./EmployeeShortProfile.css";
import { MdLocationOn,  MdOutlineCloudUpload} from "react-icons/md";
import { post } from '../../api/apiCalls.js'
import { useEffect, useRef, useState } from "react";
import { ReactSession } from 'react-client-session'
import useProfileImage from "../../../hooks/useProfileImage.js";
import { Toast } from "primereact/toast";
import { postUserPhoto } from "../../api/env.js";

function EmployeeShortProfile(props) {
  const toast = useRef(null)
  const userImage = useProfileImage()
  const [profileImage, setProfileImage] = useState('')
  const [imageBase64, setImageBase64] = useState('')
  const [userId, setUserId] = useState()
  const [user, SetUser] = useState()
  const [loaded, setLoaded] = useState(false)
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    let user = ReactSession.get("User")
    SetUser(user)
    setUserId(user.id)
    setLoaded(true)
  }, [])

  const closeModal = () => {
    let modal = document.getElementById('upload-modal')
    setProfileImage('')
    modal.setAttribute('class', 'disable')
  }

  const openModal = () => {
    let modal = document.getElementById('upload-modal')
    modal.setAttribute('class', 'able')
  }

  const showSuccess = (text) => {
    toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
  }
  const showWarning = (text) => {
    toast.current.show({severity:'warning', summary: 'Warning', detail:text, life: 3000});
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    if (file && (file.type == 'image/jpeg' || file.type == 'image/png')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64 = reader.result.split(',')
        setProfileImage(reader.result);
        setImageBase64(base64[1]);
      };
      reader.readAsDataURL(file);
    } else {
      showWarning('Please, select an JPEG or PNG file.');
    }
  };

  const uploadPicture = async() => {
    let keys = {
      userId: userId,
      imageBase64: imageBase64
    }
    let upload = await post(postUserPhoto, keys)
    if(upload){
      showSuccess("Profile picture was updated")
    }
    sessionStorage.setItem("profileImage", imageBase64)
    window.location.reload()
    closeModal()
  }

  return (
    <>
      <Toast ref={toast} position="top-center"/>
      {loaded && (
        <div className="EmployeeShortProfile">
          <div className="image-box" onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
            <img className="summaryProfileImage" src={userImage} alt="user profile" />
            {/* Check if the showIcon = true and loggedUser !==user in profile page */}
            {showIcon && user.id == props.employeeID &&(
              <div className="icone-cloud-upload">
                <MdOutlineCloudUpload onClick={openModal}/>
              </div>
            )}
          </div>
          <div className="summaryProfileInformation">
            <span className="summaryProfile-name">{props.employeeName}<span className="summaryProfile-pronoun">{' ('+props.employeePronoun+')'}</span></span>
            
            <span className="summaryProfile-designation">
              {props.employeeDesignation}
            </span>
            <span className="summaryProfile-ID">
              Employee ID: {props.employeeID}
            </span>
            <span className="summaryProfile-status">{props.employeeUsername}</span>
            <span className="summaryProfile-location">
              <MdLocationOn />
              {props.employeeLocation}
            </span>
          </div>
          <div className="upload-modal disable" id="upload-modal">
            <div className="modal-content">
              <h3>Upload Profile Picture</h3>
              <div className="modal-inputs">
                {userImage && (
                  <img 
                    className="uploaded-image"
                    src={userImage}   
                    alt="user profile"       
                  />
                )}
                <div className="modal-form">
                  <input
                    className="upload-picture"
                    type="file"
                    id="upload-input"
                    name="upload-input"
                    accept="image/png, image/jpeg"
                    max-width='200px'
                    max-height='200px'
                    onChange={handleFileChange}
                  />
                  <div className="modal-btns">
                    <button className="save-profile-picture-btn" onClick={uploadPicture}>Save</button>
                    <button className="close-upload-profile-picture-modal-btn" onClick={closeModal}>Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EmployeeShortProfile;
