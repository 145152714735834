import DateTimeRangeSelector from "../../ui/DateTimeRangeSelector";
import SelectDropdown from "../../ui/SelectDropdown";
import {get} from "../../api/apiCalls";
import "./ManualFormModal.css";
import { ReactSession } from 'react-client-session';
import { useState,useMemo } from "react";
import Alert from 'react-bootstrap/Alert';
import { getProjectForAgent } from "../../api/env";

function ManualFormModal(props) {
  let [defaultProjectSelectText,setDefaultProjectSelectText] = useState("Please Select Project");
  let [defaultReasonSelectText,setDefaultReasonSelectText] = useState("Please Select Reason");

  let [data,setData] = useState(0);

  
  function getProjectList() {
    const getProjects = 
    async () => { 
      
      let user = ReactSession.get("User");
      let rsummary = await get(getProjectForAgent+"?userID="+user.id);
      
      let list = [];
      for (const item of rsummary) {
        list.push( {
          id : item.id,
          name : item.name,
          
        });
      }
      setData(list);
    }
    getProjects();
  }
  
  const ReasonsLists = [
    { id: 1, name: "Tracking Tool Lost Time" },
    { id: 2, name: "Working on MacOS" },
    { id: 3, name: "Working on Linux" },
    { id: 4, name: "Forgot to turn on tracker" },
    { id: 5, name: "Other" },
  ];
  let [fromDate,setFromDate] = useState(0);
  let [toDate,setToDate] = useState(0);
  
  
  function addRequest() {
    let mto =  {
      'project' : defaultProjectSelectText,
      'reason' : ReasonsLists[defaultReasonSelectText-1].name,
      'from' : fromDate,
      'to' : toDate
    }
    props.setMto(mto);
  }
  
  useMemo(() => {
    getProjectList();
    // componentWillMount events
  },[]);

  return (
    <div className="manualtime-box">
      <div className="manualtime-modal">
        <h4 className="modal-title">Request Manual Time Form</h4>
        <div className="Timeform">
          <Alert variant="info">
            This feature allows you to add workhours to your timesheets, should any issues arise with the Tracker.
            <br/>For instance, if you forgot to turn on the tracker or if you have an OS that is not supported. 
            <br/><strong>MT can only be entered 7 days in the past</strong>, and please select the time based on your timezone. 
            <br/>If you have any questions, please reach out to your manager.
          </Alert>
          <div className="manual-form-modal">
            <DateTimeRangeSelector
              setFromDate = {setFromDate}
              setToDate = {setToDate}
              startplaceholderText="Select Start Date Time"
              endplaceholderText="Select End Date Time"
              forward={0}
              backward={7}
              filterTime={true}
            />
          </div>
          <div className="manual-form-modal">
            <SelectDropdown
              DefaultSelectText={setDefaultProjectSelectText}
              OptionsLists={data}
            />
            <SelectDropdown
              DefaultSelectText={setDefaultReasonSelectText}
              OptionsLists={ReasonsLists}
            />
          </div>
          <div className="mt-form-btns">
            <button onClick={addRequest} className="create-mt-btn">Create</button>
            <button onClick={props.onCancel} className="cancel-mt-btn">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManualFormModal;
