import "./PersonalInfo.css";
import TextInput from "../../ui/TextInput";
import "../../ui/TimezoneSelector.css";
import { useState , useMemo, useRef } from "react";
import { ReactSession } from 'react-client-session';
import { get, put } from "../../api/apiCalls";
import TimezoneSelect from "react-timezone-select";
import moment from "moment";
import { jsPDF } from 'jspdf';
import LogoImage from '../../../images/Sirius logo-White bg.png'
import craigFont from '../../../images/craig.png'
import ManagerSideBar from "../../layout/sidebar/ManagerSidebar"
import ProfileSubHeaderLinks from "../../layout/header/ProfileSubHeaderLinks";
import { Toast } from "primereact/toast";
import Joyride from "react-joyride"
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { getUsersAsync, getUsersEmploymentInfo, putUsers } from "../../api/env";

function PersonalInfo(props) {
  const history = useNavigate();
  const [isFirstLogin, setIsFirstLogin] = useState('true')
  const [{ run, steps }, setState] = useState({
    run:true,
    steps: [
      {
        content: "Here you can find your personal information on the system.",
        title: <h2><FaUser/> Personal Info</h2>,
        locale: {skip: <strong>SKIP</strong>},
        placement: "center",
        target: 'body',
        disableOverlay: false,
        spotlightClicks: false

      },
      {
        content: 'The detail section houses all your basic information. Please edit/add your details to complete your profile before continuing.',
        placement: "bottom",
        target: "#step-4",
        title: <h2>Details</h2>,
        spotlightClicks: true,
      }
    ]
  })

  const handleTourEvent = (event) => {
    if(event.index == 1 && event.status == 'finished' || event.action == 'skip'){
      // history("/profile/payment")
      history("/profile/work")
    }
  }

  const toast = useRef(null)
  let [userDetails,setUserDetails] = useState([]);

  const showError = (text) => {
    toast.current.show({severity:'error', summary: 'Error', detail:text, life: 3000});
  }

  const showSuccess = (text) => {
    toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
  }

  const saveChanges = async () => { 
    let keys = {
      UserId: userDetails.id,
      FirstName: userDetails.firstName,
      LastName: userDetails.lastname,
      Phone: userDetails.phone,
      Pronoun: userDetails.pronoun,
      Timezone: userDetails.timezone,
      Birthday: userDetails.birthday,
      City: userDetails.city,
      EmergencyContact: userDetails.emergencyContact,
      PersonalEmail: userDetails.personalEmail,
    }
    
    let rsummary = await put(putUsers,keys);
    if(typeof rsummary == 'undefined') {
      showError(`Impossible to convert (${userDetails.Birthday}) into a date. Follow the format (YYYY/MM/DD). 
      YYYY must have 4 characters, 
      MM must be a value between 01 -> 12, 
      DD must be a value between 01 -> 31`);
    }else{
      showSuccess("Changes Saved");
      setUserDetails(rsummary);
      ReactSession.set("User",rsummary);
    }
  }

  const generatePDF = async() => {
    let userInfo = await get(`${getUsersEmploymentInfo}/${userDetails.id}`)
    const doc = new jsPDF();

    // Header
    doc.setFontSize(20);
    doc.addImage(`${LogoImage}`, 'PNG', 120, 10, 75, 25);

    // Body
    doc.setFontSize(12);
    doc.text(`${moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}`, 20, 60);
    doc.text(`${userInfo.firstName} ${userInfo.lastName} - Employment Confirmation`, 20, 70);
    doc.text(`To Whom It May Concern:`, 20, 90);
    doc.text(`I am writing this verification for ${userInfo.firstName} ${userInfo.lastName} regarding work delivered `, 20, 110);
    doc.text(`for Sirius Support.`, 20, 120);
    doc.text(`${userInfo.firstName} started working with Sirius Support on the ${moment(userInfo.dateJoined).format('yyyy-MM-DD')} on a full-time `, 20, 130);
    doc.text(`contract with a salary of ${userInfo.rate} USD per hour working 40 hours per week.`, 20, 140);
    doc.text(`If you have any questions, please feel free to contact us at goremote@sirius-support.io `, 20, 150);
    doc.text(`or contact myself directly on the details below`, 20, 160);

    // Footer
    doc.text('Regards,', 20, 228); 
    doc.addImage(`${craigFont}`, 'PNG', 20, 230, 15, 10);
    doc.text('Craig Mills', 20, 245);
    doc.text('CEO', 20, 255);
    doc.text('Sirius Support', 20, 265);
    doc.text('craig@sirius-support.com', 20, 275);
      
    // Save
    doc.save('letter-of-employment.pdf');
  };

  useMemo(() => {
    const user = ReactSession.get("User");
    setIsFirstLogin(user.isFirstLogin)
    setUserDetails(user);
  },[]);
  
  return (
    <>
    {isFirstLogin && (
      <Joyride
        continuous
        showSkipButton
        run={run}
        steps={steps}
        showProgress
        disableScrolling
        callback={handleTourEvent}
        styles={
          {
            options:{
              zIndex: 9999
            },
            spotlight: {
              minHeight:'100%',
            }
          } 
      }
      />
    )}
    <Toast ref={toast} position="top-center"/>
      <ManagerSideBar />
      <div className="profileMain">
        <ProfileSubHeaderLinks />
        <div className="employee-main" id="step-4">
          <div className="employee-main-content">
          
            <div className="personal-info-header">Details</div>
            <button onClick={generatePDF} className="letter-btn">Letter of Employment</button>
            {userDetails.contract_Url && (
              <a className="contract-btn" href={userDetails.contract_Url} target="_blank">Open Contract</a>
            )}
            <form className="personal-info-summary">
              <TextInput
                text="First Name"
                type="text"
                required="required"
                value={userDetails.firstName}
                onChangeText={(value) => setUserDetails({...userDetails, firstName: value})}

                define={"firstName"}
              /> 
              <TextInput
                text="Last Name"
                type="text"
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, lastname: value})}

                value={userDetails.lastname}
              />
              <TextInput
                text="Email"
                type="email"
                disabled='true'
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, username: value})}

                value={userDetails.username}
              />
              <TextInput
                text="Job Title"
                type="text"
                disabled='true'
                required="required"
                value={userDetails.jobDescription}
                onChangeText={(value) => setUserDetails({...userDetails, jobDescription: value})}
              />

              <TextInput
                text="Phone"
                type="text"
                onChangeText={(value) => setUserDetails({...userDetails, phone: value})}

                value={userDetails.phone}
              />
              <TextInput
                text="Preferred Pronoun"
                type="text"
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, pronoun: value})}

                value={userDetails.pronoun}
              />
              <TextInput
                text="Birthday - yyyy/mm/dd"
                type="text"
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, birthday: value})}

                value={userDetails.birthday}
              />
              <TextInput
                text="City / Country"
                type="text"
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, city: value})}

                value={userDetails.city}
              />
              <TextInput text="Emergency Contact" 
                type="text" 
                value={userDetails.emergencyContact}
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, emergencyContact: value})} />
              
              <TextInput
                text="Personal Email"
                type="text"
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, personalEmail: value})}

                value={userDetails.personalEmail}
              />

              <TextInput
                text="Contract Expire Date"
                type="text"
                disabled='true'
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, contract_Expire_Date: value})}

                value={userDetails.contract_Expire_Date}
              />

              <TextInput
                text="Contract Status"
                type="text"
                disabled='true'
                required="required"
                onChangeText={(value) => setUserDetails({...userDetails, contract_Status: value})}

                value={userDetails.contract_Status}
              />
              <span>
                <label htmlFor="timezone-select" className="timezone-label">Select your Timezone</label>
                <TimezoneSelect 
                  id="timezone-select"
                  className="TimezoneSelect"
                  value={userDetails.timezone}
                  onChange={(value) => setUserDetails({...userDetails, timezone: value.value})} 
                />
              </span>

            </form>
            {!userDetails.disabled && (
              <div className="personal-info-btns-box">
                <button 
                disabled={!userDetails.firstName || !userDetails.lastname || !userDetails.username || !userDetails.jobDescription || !userDetails.phone || !userDetails.pronoun || !userDetails.birthday || !userDetails.city || !userDetails.emergencyContact || !userDetails.personalEmail}
                onClick={saveChanges} 
                type="submit" 
                className="personal-info-button">
                  Save Changes
                </button>
                <small>All fields are mandatory</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalInfo;
