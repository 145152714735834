import SubHeaderLinks from "../../layout/header/SubHeaderLinks";
import "../../elements/diaryElements/DiaryHours.css";
import "./Diary.css";
import DateSelector from "../../ui/DateSelector";
import moment from 'moment';
import React, { useState,useMemo, useEffect} from 'react';
import { ReactSession } from 'react-client-session';
import { newPost, get, post, put } from "../../api/apiCalls";
import DiaryTimeLine from "../../elements/diaryElements/DiaryTimeline";
import ConfirmDeleteModal from "../../ui/ConfirmDeleteModal";
import ConfirmActivateModal from "../../ui/ConfirmActivateModal";
import Backdrop from "../../ui/Backdrop";
import TimezoneSelector from "../../ui/TimezoneSelector";
import LoadingSpinner from '../../elements/UiElements/LoadingSpinner'
import SubMenuLinks from "../../layout/header/SubMenuLinks";
import { Dialog } from 'primereact/dialog';
import { deleteTrackingDataAudit, getTrackingData, putTrackingDataAudit } from "../../api/env";


function Diary(props) {
  let [lastDate, setdate] = useState(moment().format("YYYY-MM-DD"));
  const [visible, setVisible] = useState(false)
  let [timeOffset,setTimeOffset] = useState(0);
  let [summary, setSummary] = useState([]);
  let [loading,setLoading] = useState(false);
  const [isManager, setIsManager]= useState(false);
  let[loadingImages, setLoadingImages]= useState(true);
  const [items, setitems] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmActivate, setConfirmActivate] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [isManuallySelected, setIsManuallySelected] = useState(false);

  

  const handleSelectAll = () => {
    setIsManuallySelected(false); 
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };


  function deleteHandler() {
    setConfirmDelete(true);
  }

  function activateHandler() {
    setConfirmActivate(true);
  }

  function CloseModal() {
    setConfirmActivate(false);
    setConfirmDelete(false);
    setitems([]);
  }

  function ConfirmDeleteHandler() {
    deleteCards();
    setConfirmActivate(false);
    setConfirmDelete(false);
  }

  function ConfirmActivateHandler() {
    markCardActive();
    setConfirmActivate(false);
    setConfirmDelete(false);
  }

  let markCardActive = async()  => {
    let checked = document.querySelectorAll('input:checked');
    let num = [];
    for (const item of checked) {
      num.push(parseInt(item.id));     
      item.checked = false;
    }

    let keys = {
      cardsID: num,
      id: user.id,
      changedBy: user.username,
      status: 4
    };

    await put(putTrackingDataAudit,keys)
    getSummaryDetails();
    setSelectedBoxes([])
    setSelectAll(false)
  }

  let deleteCards = async () => {
    let checked = document.querySelectorAll('input:checked');
    let num = [];
    for (const item of checked) {
      num.push(parseInt(item.id));
      item.checked = false;
    }
  
    let keys = {
      cardsID: num,
      id: user.id
    };
  
    await newPost(deleteTrackingDataAudit, keys).then(response => {
      getSummaryDetails();
    });
    setSelectedBoxes([])
    setSelectAll(false);
  }
  
  //End of mark/delete
  const setDiaryDate = (date) => {
    setdate(date);
    lastDate = date;
    getSummaryDetails();
  };

  const getSummaryDetails = async (offset) => {
		setLoading(true);
		const keys = {
		  date: moment(lastDate).format("YYYY-MM-DD"),
		  id: user.id,
      offset
		};
	  
		let summary = await fetchSummaryData(keys);
		if (summary !== 0 ) {
      setSummary(summary);
		}
    setLoading(false);
    setLoadingImages(false)
	  
	};
  

	const fetchSummaryData = async (keys) => {
		try {
		  const response = await get(getTrackingData, keys);
		  return response;
		} catch (error) {
		  console.error("Error fetching summary data:", error);
		  return undefined;
		}
	};
  

  const handleSelection = (state, id) => {
    setSelectedBoxes((prevSelectedBoxes) => {
      const updatedSelectedBoxes = state
        ? [...prevSelectedBoxes, id]
        : prevSelectedBoxes.filter((boxId) => boxId !== id);

      setIsManuallySelected(true); 

      if (updatedSelectedBoxes.length === document.querySelectorAll('.cards-checkboxes').length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }

      return updatedSelectedBoxes;
    });
  };

  let user = ReactSession.get("User");

  useEffect(() => {
  
    if (isManuallySelected) return;
    
    const checkboxes = document.querySelectorAll('.cards-checkboxes');
    if (selectAll) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
      setSelectedBoxes(Array.from(checkboxes).map((checkbox) => checkbox.id));
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      setSelectedBoxes([]);
    }
  }, [selectAll, isManuallySelected]);

  useMemo(async () => {
    if(summary===0)
      await getSummaryDetails();
    if(user.role === 1 || user.role === 3){
      setIsManager(true)
    } 
    // componentWillMount events
  },[]);
  
  let changeTimeOffset = (Offset) => {
      timeOffset = Offset
      getSummaryDetails(timeOffset);
  }

  if(summary.length <= 0){
    return(<div className="diary">
      {/* <SearchSection /> */}
      <div>
        <SubMenuLinks />
        <div className="diaryMain step-0">
          <div className="filters">
            
            <span>Diary</span>
            <DateSelector placeholderText={moment().format("YYYY-MM-DD")} updateTimers={setDiaryDate} />
            <span>Timezone</span>
            <TimezoneSelector setTimeOffset={changeTimeOffset} value={'Etc/GMT'} />
            {loading && <LoadingSpinner />}
          </div>
          {!loading && <p className="no-data-msg">No timecards for the selected day</p>}
        </div>
      </div>
    </div>
                

    );

    
  }
 

  return (
    <div className="diary">
      {/* <SearchSection /> */}
      <div>
        <SubMenuLinks />
        <div className="diaryMain step-0">
          <div className="filters">
           
            <span>Diary</span>
            <DateSelector placeholderText={moment().format("YYYY-MM-DD")} updateTimers={setDiaryDate} />
            <span>Timezone</span>
            <TimezoneSelector setTimeOffset={changeTimeOffset} />
            {loading && <LoadingSpinner />}
          </div>
          {!loadingImages && (
            <div className="diary-cards-box">
              <h4 className="diary-cards-title">Diary cards</h4>
              {!user.disabled && summary.length > 0 &&(
                <div className="timecard-action-box">
                  <button
                    className="timecards-action-btns"
                    id="select-all-cards"
                    onClick={handleSelectAll}
                  >
                    {selectedBoxes.length <= 0 ? (
                      <>
                        Select All
                      </>

                    ): (
                      <>
                        Clear Selection
                      </>
                    )}
                  </button>
                  <button
                    className="timecards-action-btns"
                    id="timecard-status"
                    onClick={() => setVisible(!visible)}
                  >
                    Timecard Status
                  </button>
                  <button
                    className="timecards-action-btns"
                    id="delete-cards"
                    disabled={selectedBoxes.length <= 0}
                    onClick={deleteHandler}
                  >
                    Delete Time Cards
                  </button>
                  <button
                    className="timecards-action-btns"
                    id="active-cards"
                    disabled={selectedBoxes.length <= 0}
                    onClick={activateHandler}
                  >
                    Mark as Active
                  </button>
                </div>
              )}
              <DiaryTimeLine
                handleSelection={handleSelection}
                data = {summary}
              />
            </div>
          )}
         
        </div>
      </div>
      {confirmDelete && (
        <ConfirmDeleteModal
          items={items}
          ConfirmDelete={ConfirmDeleteHandler}
          onCancel={CloseModal}
        />
      )}
      {confirmActivate && (
        <ConfirmActivateModal
          items={items}
          ConfirmActivate={ConfirmActivateHandler}
          onCancel={CloseModal}
        />
      )}
      {confirmDelete && <Backdrop onCancel={CloseModal} />}
      {confirmActivate && <Backdrop onCancel={CloseModal} />}

      <Dialog 
      header="Timecards Status" 
      visible={visible} 
      style={{ width: '50vw' }} 
      onHide={() => {if (!visible) return; setVisible(false); }}
      >
        <div>
          <table className="timecard-dialog">
            <thead className="timecard-dialog">
                <tr className="timecard-dialog">
                    <th className="timecard-dialog">Status</th>
                    <th className="timecard-dialog">Description</th>
                    <th className="timecard-dialog">Added to Payroll</th>
                </tr>
            </thead>
            <tbody className="timecard-dialog">
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Idle</td>
                <td className="timecard-dialog">Idle timecard is when the tracker did not identify any activity.</td>
                <td className="timecard-dialog">No</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Productive</td>
                <td className="timecard-dialog">A productive and valid timecard</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Manual Time</td>
                <td className="timecard-dialog">A timecard created after MT was approved</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Marked</td>
                <td className="timecard-dialog">An idle timecard marked as not idle by the user. Example: the user was in a meeting or watching a training.</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Rejected</td>
                <td className="timecard-dialog">A timecard that has been rejected by the manager </td>
                <td className="timecard-dialog">No</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button className="ok-dialog-btn" onClick={() => setVisible(!visible)}>Ok</button>
          
      </Dialog>
    </div>
    
  );
}

export default Diary;
