import './AuditDetails.css';
import DataTableBase from '../../ui/DataTableBase.js';
import {get} from "../../api/apiCalls";
import React, { useState,useMemo} from 'react';
import moment from 'moment';
import DateRangeSelector from "../../ui/DateRangeSelector";
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";
import { getLogger } from '../../api/env.js';
function AuditDetails(props) {
 
  let [from, setFrom] = useState(moment().startOf('day').utc().format());
  let [to, setTo] = useState(moment().utc().add(1, 'days').format());
  let [projects,setProjects] = useState([]);
  let [loading,setLoading] = useState(true);

  const columns = [
    {
      id: 'Id',
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'Level',
      name: 'Level',
      selector: (row) => row.level,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'Message',
      name: 'Log Details',
      selector: (row) => <div className='cell'><div className="cell-overflow">{row.message}</div></div>,
      sortable: true,
      reorder: true,
      wrap: true,
      
    },
    {
      id: 'TimeStamp',
      name: 'TimeStamp',
      selector: (row) => row.TimeStamp,
      sortable: true,
      reorder: true,
      wrap: true,
    },
  
  ];

  const setFromDate = (date) => {
    setFrom(date);
    from = date;
    getAuditLog();
  };

  const setToDate = (date) => {
    setTo(date);
    to=date;
    getAuditLog();
  };
  
  const getAuditLog = async() => {
    setLoading(true);
    let keys = {
      search : "aa",
      startTime : moment(from).utc().format(),
      endTime : moment(to).format("YYYY-MM-DD 23:59:59")
    }
    let auditResult = await get(`${getLogger}?`+new URLSearchParams(keys).toString());
    let audits = [];
    for (const item of auditResult) {
      let audit = {
      id : item.Id,
      message : item.Message,
      messageTemplate : item.MessageTemplate,
      level : item.Level,
      TimeStamp : item.TimeStamp
      }
      audits.push(audit);
    }
    setProjects(audits);
    setLoading(false);
  }

  useMemo(() => {
    getAuditLog();
  },[]);

  return (
    
    <div className='dashboardProjectsMain'>
       
      <div className='dashboardProjectsMain-header'>Audit Log</div>
      <DateRangeSelector
                    onFromChangeDate = {setFromDate}
                    onToChangeDate = {setToDate}  />
      {(() => {
              if (!loading){
                  return (
                    <div>

                    <DataTableBase
                      columns={columns}
                      data={projects}
                      tableName='ProjectsReport'
                      showDownloadButton={false}
                    />
                   
                    </div>
                  )
              }else {
                return <LoadingSpinner/>
              }
      })()}
   
    </div>
  );
}

export default AuditDetails;
