import { RiTeamFill, RiUserSettingsFill } from "react-icons/ri";
import { MdOutlinePayments  } from "react-icons/md";
import { BsLink45Deg } from "react-icons/bs"
import { BiDetail } from "react-icons/bi";
import { FaUser,FaProjectDiagram, FaHistory } from "react-icons/fa";
import { GrDocumentTime, GrMoney } from "react-icons/gr";
import {AiOutlineAudit,AiFillSetting} from "react-icons/ai"
import { ImInsertTemplate } from "react-icons/im";
import { IconContext } from "react-icons";
import { NavLink } from "react-router-dom";
import Tooltip from "../../ui/Tooltip";
import { ReactSession } from 'react-client-session';


import "./Sidebar.css";


function ManagerSidebar() {
  let isAdmin = false
  let isManager = false
  let isCustomer = false

  let user = ReactSession.get("User");
  if(user.role === 1) isAdmin = true
  if(user.role === 3 || user.role === 1) isManager = true
  if(user.role === 4) isCustomer = true
  return (
    <nav className="sidebar" id="manager-sidebar">
      <IconContext.Provider
        value={{
          color: "gray",
          size: "40px",
        }}
      >
        <div className="team-nav-group nav-group">
        {isManager && (
          <div>
            <Tooltip content="Manager Dashboard" direction="right">
              <NavLink to="/manager" className={location.pathname === "/manager" ? "active" : ""} end>
                <RiTeamFill />
                <ImInsertTemplate />
              </NavLink>
            </Tooltip>
        
            <Tooltip content="Payroll Details" direction="right">
              <NavLink to="/manager/payrolldetails" className={location.pathname === "/manager/payrolldetails" ? "active" : ""}>
                <RiTeamFill />
                <GrMoney />
              </NavLink>
            </Tooltip>
        
            <Tooltip content="Project Details" direction="right">
              <NavLink to="/manager/projects" className={location.pathname === "/manager/projects" ? "active" : ""}>
                <RiTeamFill />
                <FaProjectDiagram />
              </NavLink>
            </Tooltip>
        
            <Tooltip content="Audit Log" direction="right">
              <NavLink to="/manager/audit" className={location.pathname === "/manager/audit" ? "active" : ""}>
                <RiTeamFill />
                <AiOutlineAudit />
              </NavLink>
            </Tooltip>
        
            <Tooltip content="Additional Payments" direction="right">
              <NavLink to="/manager/additional-payments" className={location.pathname === "/manager/additional-payments" ? "active" : ""}>
                <RiTeamFill />
                <MdOutlinePayments />
              </NavLink>
            </Tooltip>
        
            <Tooltip content="Manual Time Approval" direction="right">
              <NavLink to="/manager/manual-time-approval" className={location.pathname === "/manager/manual-time-approval" ? "active" : ""}>
                <RiTeamFill />
                <FaHistory />
              </NavLink>
            </Tooltip>
          </div>
          )}
         
        </div>

        <div className="team-nav-group nav-group" id="step-sidebar">
        {!isCustomer && (
          <div>
          <Tooltip content="My Dashboard" direction="right">
            <NavLink to="/summary" className={({ isActive }) => isActive ? "active" : "" }>
              <ImInsertTemplate />
              {/* <MdSummarize /> */}
            </NavLink>
          </Tooltip>
          <Tooltip content="My Daily Diary" direction="right">
            <NavLink to="/diary" className={({ isActive }) => isActive ? "active" : "" }>
              <BiDetail />
            </NavLink>
          </Tooltip>

          <div id="step-3">
            <Tooltip content="My Info" direction="right">
              <NavLink to="/profile/info" className={({ isActive }) => isActive ? "active" : "" }>
                <FaUser />
              </NavLink>
            </Tooltip>
          </div>
          {!user.disabled &&(
            <Tooltip content="Links" direction="right">
              <NavLink to="/profile/work" className={({ isActive }) => isActive ? "active" : "" }>
                <BsLink45Deg />
              </NavLink>
            </Tooltip>
          )}

          <Tooltip content="My Requests" direction="right">
            <NavLink to="/profile/requests" className={({ isActive }) => isActive ? "active" : "" }>
              <GrDocumentTime />
            </NavLink>
          </Tooltip>

          
          </div>
        )}
        </div>

        <div className="team-nav-group nav-group">
        {isCustomer && (
          <Tooltip content="Team Dashboard" direction="right">
              <NavLink to="/team" className={({ isActive }) => isActive ? "active" : "" }>
                <RiTeamFill />
                <ImInsertTemplate />
              </NavLink>
          </Tooltip>
        )}
        </div>

        <div className="team-nav-group nav-group">
        {isAdmin && (
          <Tooltip content="System Settings" direction="right">
            <NavLink to="/settings/sso-setup" className={({ isActive }) => isActive ? "active" : "" }>
              <AiFillSetting />
            </NavLink>
         </Tooltip>
        )}
        {isAdmin && (
          <Tooltip content="User Settings" direction="right">
            <NavLink to="/user-settings/user-role" className={({ isActive }) => isActive ? "active" : "" }>
              <RiUserSettingsFill />
            </NavLink>
         </Tooltip>
        )}
        </div>

      </IconContext.Provider>
    </nav>
  );
}

export default ManagerSidebar;
