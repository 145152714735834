import "./AdminComplianceSettingsPage.css";
import AdminHeaderLinks from "../layout/header/AdminHeaderLinks";
import { Box, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { get, put } from "../api/apiCalls";
import { Toast } from "primereact/toast";
import { getComplianceSettings, putComplianceSettings } from "../api/env";

function AdminComplianceSettingsPage() {
    const toast = useRef(null);
    const [complianceSetting, setComplianceSetting] = useState({});
    const [maxKeystrokes, setMaxKeystrokes] = useState(0);
    const [maxMouseClicks, setMaxMouseClicks] = useState(0);
    const [maxRepetingKeystrokes, setMaxRepetingKeystrokes] = useState(0);
    const [maxRepetingMouseClicks, setMaxRepetingMouseClicks] = useState(0);
    const [initMaxKeystrokes, setInitMaxKeystrokes] = useState(0);
    const [initMaxMouseClicks, setInitMaxMouseClicks] = useState(0);
    const [initMaxRepetingKeystrokes, setInitMaxRepetingKeystrokes] = useState(0);
    const [initMaxRepetingMouseClicks, setInitMaxRepetingMouseClicks] = useState(0);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await listComplianceSettings();
            } catch (error) {
                console.error("Error fetching compliance settings:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        setIsChanged(
            maxKeystrokes !== initMaxKeystrokes ||
            maxMouseClicks !== initMaxMouseClicks ||
            maxRepetingKeystrokes !== initMaxRepetingKeystrokes ||
            maxRepetingMouseClicks !== initMaxRepetingMouseClicks
        );
    }, [maxKeystrokes, maxMouseClicks, maxRepetingKeystrokes, maxRepetingMouseClicks]);

    async function listComplianceSettings() {
        let response = await get(getComplianceSettings);

        setInitMaxKeystrokes(response.maxKeystrokes);
        setInitMaxMouseClicks(response.maxMouseClicks);
        setInitMaxRepetingKeystrokes(response.maxRepetingKeystrokes);
        setInitMaxRepetingMouseClicks(response.maxRepetingMouseClicks);

        setMaxKeystrokes(response.maxKeystrokes);
        setMaxMouseClicks(response.maxMouseClicks);
        setMaxRepetingKeystrokes(response.maxRepetingKeystrokes);
        setMaxRepetingMouseClicks(response.maxRepetingMouseClicks);

        setComplianceSetting(response);
    }

    const showSuccess = (text) => {
        toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
    }

    async function handleUpdateSettings(e) {
        e.preventDefault()
        let keys = {
            ID: complianceSetting.id,
            MaxKeystrokes: maxKeystrokes,
            MaxMouseClicks: maxMouseClicks,
            MaxRepetingKeystrokes: maxRepetingKeystrokes,
            MaxRepetingMouseClicks: maxRepetingMouseClicks
        }

        let response = await put(putComplianceSettings, keys)
        showSuccess("Compliance Settings was updated")
        setInitMaxKeystrokes(response.maxKeystrokes);
        setInitMaxMouseClicks(response.maxMouseClicks);
        setInitMaxRepetingKeystrokes(response.maxRepetingKeystrokes);
        setInitMaxRepetingMouseClicks(response.maxRepetingMouseClicks);
    }

    return (
        <div className="settingsContainer">
            <AdminHeaderLinks />
            <Toast ref={toast} position="top-center" />
            {
                <Box sx={{ flexGrow: 1 }} className="settingsBody">
                    <Grid container spacing={0}>
                        <Grid xs={10}>
                            <h3 className="settingsLabel">Compliance Settings</h3>
                        </Grid>
                        <Grid xs={10}>
                            <form className="compliance-settings-form" onSubmit={handleUpdateSettings}>
                                <p className="compliance-settings-read-me">*<i>This configuration is the default for the compliance checks, if the project does not have a specific setup these will be applied.</i></p>
                                <div>
                                    <label htmlFor="max-keystrokes-input">Max Keystrokes</label>
                                    <input
                                        id="max-keystrokes-input"
                                        type="number"
                                        value={maxKeystrokes}
                                        min={0}
                                        onChange={(e) => setMaxKeystrokes(Number(e.target.value))}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="max-mouseclicks-input">Max Mouse Clicks</label>
                                    <input
                                        id="max-mouseclicks-input"
                                        type="number"
                                        value={maxMouseClicks}
                                        min={0}
                                        onChange={(e) => setMaxMouseClicks(Number(e.target.value))}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="max-repeting-keystrokes-input">Max Repeating Keystrokes</label>
                                    <input
                                        id="max-repeting-keystrokes-input"
                                        type="number"
                                        value={maxRepetingKeystrokes}
                                        min={0}
                                        onChange={(e) => setMaxRepetingKeystrokes(Number(e.target.value))}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="max-repeting-mouseclicks-input">Max Repeating Mouse Clicks</label>
                                    <input
                                        id="max-repeting-mouseclicks-input"
                                        type="number"
                                        value={maxRepetingMouseClicks}
                                        min={0}
                                        onChange={(e) => setMaxRepetingMouseClicks(Number(e.target.value))}
                                    />
                                </div>
                                <button
                                    disabled={!isChanged}
                                    type="submit"
                                    className="compliance-settings-save-btn"
                                >
                                    Save
                                </button>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            }
        </div>
    );
}

export default AdminComplianceSettingsPage;





