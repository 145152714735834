import "./EmployeeShortProfile.css";
import { MdLocationOn,  MdOutlineCloudUpload} from "react-icons/md";
import { post, fetchImageFromAPI } from '../../api/apiCalls.js'
import { baseURL, getTrackingDataProfilePicture, postUserPhoto } from "../../api/env";
import { useEffect, useRef, useState } from "react";
import { ReactSession } from 'react-client-session'
import { Toast } from "primereact/toast";

function UserShorProfile(props) {
  const toast = useRef(null)
  const [currentProfileImage, setCurrentProfileImage] = useState(props.employeePicture)
  const [profileImage, setProfileImage] = useState('')
  const [imageBase64, setImageBase64] = useState('')
  const [userId, setUserId] = useState()
  const [user, setUser] = useState()
  const [loaded, setLoaded] = useState(false)
  const [showIcon, setShowIcon] = useState(false);
  const [offboardingUrl, setOffboardingUrl] = useState('')
  const [renewContract, setRenewContract] = useState('')
  const [disabled, setDisabled] = useState('')

  useEffect(() => {
    let user = ReactSession.get("User")
    setUser(user)
    setUserId(user.id)
    let username = props.employeeUsername.split('@')
    setOffboardingUrl(`https://sirius-support.atlassian.net/servicedesk/customer/portal/6/group/20/create/85?customfield_10081=${username[0]}`)
    let checkContractEndDate = validateContractDate(props.employeeContractEndDate)
    if(checkContractEndDate){
      setRenewContract(`https://sirius-support.atlassian.net/servicedesk/customer/portal/6/group/20/create/108?customfield_10081=${props.employeeUsername}&customfield_10176=${user.username}`)
    }
    let isUserDisabled = props.employeeDisabled === true ? 'Disabled' : 'Active'
    setDisabled(isUserDisabled)
    setLoaded(true)
  }, [])

  const closeModal = () => {
    let modal = document.getElementById('upload-modal')
    setProfileImage('')
    modal.setAttribute('class', 'disable')
  }

  const validateContractDate = (employeeContractEndDate) => {
    // Parse the employee contract end date
    const endDate = new Date(employeeContractEndDate);
    
    // Get today's date
    const today = new Date();
    
    // Calculate the difference in time (milliseconds)
    const timeDifference = endDate - today;
    
    // Convert the difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    
    // Check if the difference is 30 days or less
    return daysDifference <= 30;
  }

  const openModal = () => {
    let modal = document.getElementById('upload-modal')
    modal.setAttribute('class', 'able')
  }

  const showWarning = (text) => {
    toast.current.show({severity:'warning', summary: 'Warning', detail:text, life: 3000});
  }

  const showSuccess = (text) => {
    toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    if (file && (file.type == 'image/jpeg' || file.type == 'image/png')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64 = reader.result.split(',')
        setProfileImage(reader.result);
        setImageBase64(base64[1]);
      };
      reader.readAsDataURL(file);
    } else {
      showWarning('Please, select an JPEG or PNG file.');
    }
  };

  const uploadPicture = async() => {
    let keys = {
      userId: userId,
      imageBase64: imageBase64
    }
    let upload = await post(postUserPhoto, keys)
    if(upload){
      showSuccess("Profile picture was updated")
      user.image = upload
      ReactSession.set("User", user)
    }
    getUserProfileImage(upload)
    window.location.reload()
    closeModal()
  }

  const getUserProfileImage = async(imageName) => {
    let img = await fetchImageFromAPI(baseURL + getTrackingDataProfilePicture + "?ImagePath=" +imageName);
    setCurrentProfileImage(img)
  }

  return (
    <>
      <Toast ref={toast} position="top-center"/>
      {loaded && (
        <div className="EmployeeShortProfile">
          <div className="image-box" onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
            <img className="summaryProfileImage" src={currentProfileImage} alt="" />
            {/* Check if the showIcon = true and loggedUser !==user in profile page */}
            {showIcon && user.id == props.employeeID &&(
              <div className="icone-cloud-upload">
                <MdOutlineCloudUpload onClick={openModal}/>
              </div>
            )}
          </div>
          <div className="summaryProfileInformation">
            <span className="summaryProfile-name">{props.employeeName}<span className="summaryProfile-pronoun">{' ('+props.employeePronoun+')'}</span></span>
            
            <span className="summaryProfile-designation">
              {props.employeeDesignation}
            </span>
            <span className="summaryProfile-ID">
              Employee ID: {props.employeeID}
            </span>
            <span className="summaryProfile-status">{props.employeeUsername}</span>
            <span className="summaryProfile-location">
              <MdLocationOn />
              {props.employeeLocation}
            </span>
            <div className="user-status-box">
              <span className="summaryProfile-disabled">Status: {disabled}</span>
              {props.employeeDisabled === false && (
                <div className="user-status-btns">
                <a className="summaryProfile-offboarding" href={offboardingUrl} target="_blank">
                  Offboard User
                </a>
                {renewContract !== '' && (
                  <a className="summaryProfile-renewContract" href={renewContract} target="_blank">
                    Renew contract
                  </a>
                )}
                </div>
              )}
            </div>
          </div>
          <div className="upload-modal disable" id="upload-modal">
            <div className="modal-content">
              <h3>Upload Profile Picture</h3>
              <div className="modal-inputs">
                {currentProfileImage && (
                  <img 
                  className="uploaded-image"
                    src={currentProfileImage}  
                    alt="current user profile"        
                  />
                )}
                <div className="modal-form">
                  <input
                    className="upload-picture"
                    type="file"
                    id="upload-input"
                    name="upload-input"
                    accept="image/png, image/jpeg"
                    width='200px'
                    height='200px'
                    onChange={handleFileChange}
                  />
                  <div className="modal-btns">
                    <button className="save-profile-picture-btn" onClick={uploadPicture}>Save</button>
                    <button className="close-upload-profile-picture-modal-btn" onClick={closeModal}>Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserShorProfile;
