import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import './layouts.css'
import { useEffect, useRef, useState } from "react";
import ManagerSidebar from "./sidebar/ManagerSidebar";

export function AdminLayout(){
    const [loadingWorkForce, setLoadingWorkForce] = useState(false)

    function onOpenWorkforce(){
        setLoadingWorkForce(true)
    }

    const workforceRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (workforceRef.current && !workforceRef.current.contains(event.target)) {
                setLoadingWorkForce(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setLoadingWorkForce]);
    return (
        <div>
            <Header title='Wisdom Dashboard' onOpenWorkforce={onOpenWorkforce}/>
            <div className="layout-container">
                <ManagerSidebar />
                {loadingWorkForce ? (
                    <div 
                    className="layout-outlet workforce-container"
                    ref={workforceRef}
                    >
                        <iframe
                            title="wfm"
                            width="100%"
                            height="100%"
                            src="http://localhost:8080/">
                        </iframe>
                    </div>
                ): (
                    <div className="layout-outlet">
                        <Outlet />
                    </div>
                )}
            </div>
        </div>
    )
}